import React, { useState, useEffect } from 'react';
import * as queryString from 'query-string';
import QRCode from 'qrcode.react';

import loader from '../images/jobs/loader.svg';
import Layout from '../components/Layout';
import MetaData from '../components/meta/MetaData';
import { extLinkClick } from '../utils/general';
import {
	ReferralFooter,
	Headline,
	ReferralOption,
	ReferralHeadline,
	ReferralContainer,
	CrowdLink,
	ReferralHeader,
	ReferralWrapper,
	ReferralTitle,
	ReferralBody,
	Loader,
	ModalSpinner,
	ReferralQRContainer,
} from '../styles';

const Referral = ({ location }) => {
	const [load, setLoad] = useState(true);
	const { code } = queryString.parse(location.search);

	useEffect(() => {
		setTimeout(() => {
			setLoad(false);
		}, 1500);
	}, []);

	return (
		<Layout>
			<MetaData
				title="Envel - Referral Code"
				description="Referral code for invitation to Envel"
				image="images/social/social.png"
			/>

			{load ? (
				<ModalSpinner>
					<Loader src={loader} />
				</ModalSpinner>
			) : code ? (
				<>
					<ReferralHeader center>Referral Code</ReferralHeader>
					<ReferralHeadline center>To use your Referral Code please follow one of the options below</ReferralHeadline>

					<ReferralContainer row>
						<ReferralBody className="left">
							<ReferralWrapper>
								<ReferralOption>OPTION 1</ReferralOption>
								<ReferralTitle>Scan The QR Code</ReferralTitle>
								<Headline>
									Use your mobile phone to scan the QR code and be directed to the app using your referral code.
								</Headline>
								<ReferralQRContainer alignCenter>
									<QRCode value={`https://envel.ai/r/${code}`} size={200} bgColor="#00000000" fgColor="#fff" />
								</ReferralQRContainer>
							</ReferralWrapper>
						</ReferralBody>

						<ReferralBody>
							<ReferralWrapper>
								<ReferralOption>OPTION 2</ReferralOption>
								<ReferralTitle>Open The Link From Your Mobile</ReferralTitle>
								<Headline>
									Use your mobile phone to tap the link{' '}
									<CrowdLink onClick={() => extLinkClick(`https://envel.ai/r/${code}`)}>
										{`envel.ai/r/${code}`}
									</CrowdLink>
									{'. '}
									You will then be directed to the app using your referral code.
								</Headline>
							</ReferralWrapper>
						</ReferralBody>
					</ReferralContainer>

					{/* <ReferralWrapper row justifyAround alignCenter>
						<ReferralBody className="top">
							<ReferralOption>OPTION 1</ReferralOption>
							<ReferralTitle>Scan The QR Code</ReferralTitle>
							<Headline>
								Use your mobile phone to scan the QR code and be directed to the app using your referral code.
							</Headline>
						</ReferralBody>
						<QRCode value={`https://envel.ai/r/${code}`} size={200} bgColor="#00000000" fgColor="#fff" />
					</ReferralWrapper>

					<ReferralContainer row>
						<ReferralBody className="left">
							<ReferralWrapper>
								<ReferralOption>OPTION 2</ReferralOption>
								<ReferralTitle>Open The Link From Your Mobile</ReferralTitle>
								<Headline>
									Use your mobile phone to tap the link{' '}
									<CrowdLink onClick={() => extLinkClick(`https://envel.ai/r/${code}`)}>
										{`envel.ai/r/${code}`}
									</CrowdLink>
									{'. '}
									You will then be directed to the app using your referral code.
								</Headline>
							</ReferralWrapper>
						</ReferralBody>

						<ReferralBody>
							<ReferralWrapper>
								<ReferralOption>OPTION 3</ReferralOption>
								<ReferralTitle>Type Your Code During Sign Up</ReferralTitle>
								<Headline>
									Download and open the Envel App, then type your referral code <CrowdLink>{code}</CrowdLink> during
									sign up.
								</Headline>
							</ReferralWrapper>
						</ReferralBody>
					</ReferralContainer> */}

					<ReferralFooter center>
						Please contact us if this is not working for you on{' '}
						<a href="mailto:support@envel.ai" target="_blank" rel="noopener noreferrer">
							support@envel.ai
						</a>{' '}
						or via the chat on the Envel app
					</ReferralFooter>
				</>
			) : (
				<>
					<ReferralHeader center>Referral Code</ReferralHeader>
					<ReferralHeadline center>
						No Referral Code was given, please go into the Envel App to get and share your Referral Code.
					</ReferralHeadline>
				</>
			)}
		</Layout>
	);
};

export default Referral;
